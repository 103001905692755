import classes from './EmailSent.module.css'
import BackTo from '../../../Shared/BackTo'

interface Props {
  returnUrl: string | null
}

const EmailSent = ({ returnUrl }: Props) => (
  <>
    <div className={classes.Title}>
      <span
        className={`${classes.SuccessEmail} ${classes.SuccessEmailPositioning}`}
      />
    </div>
    <section className={classes.Section}>
      <h1 className={classes.Title}>Check Your Email</h1>
      <p className={classes.DescriptionContainer}>
        <span>
          If the provided user ID is linked to an account, an email containing
          instructions on resetting your password has been sent.
        </span>
      </p>
      <BackTo returnUrl={returnUrl} />
    </section>
  </>
)

export default EmailSent

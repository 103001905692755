import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Header from '../../Shared/Header'
import Body from './Body'
import PasswordChanged from './PasswordChanged'

function ChangePassword() {
  const [passwordChanged, setPasswordChanged] = useState(false)
  const parameters = new URLSearchParams(useLocation().search)
  const app: string | null = parameters.get('a')
  const expired: string | null = parameters.get('expired')
  const title = expired ? 'Your Password Has Expired' : 'Change Your Password'

  if (passwordChanged) {
    return (
      <>
        <Header
          title='Password Changed'
          description='You have successfully changed your password.'
        />
        <PasswordChanged />
      </>
    )
  }

  return (
    <>
      <Header title={title} />
      <Body
        app={app}
        setPasswordChanged={setPasswordChanged}
        showBackTo={!expired}
      />
    </>
  )
}

export default ChangePassword

import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

const site: string = 'datadoghq.com'
const service: string = 'auth0-web'

export default function initDatadog() {
  const clientToken: string =
    import.meta.env.VITE_APP_DATADOG_CLIENT_TOKEN ||
    'pubce2c54dd35ac10ae6e2d251bdd4d44e7'
  const applicationId: string =
    import.meta.env.VITE_APP_DATADOG_APPLICATION_ID ||
    '1bae4aa4-b30d-4090-bb00-1e8937372b73'

  const env: string | undefined = import.meta.env.VITE_APP_ENV
  const shouldLog = ['production', 'staging', 'qa', 'dev'].includes(env)
  const sampleRate = env === 'production' ? 100 : 0
  const version = import.meta.env.VITE_APP_VERSION

  if (!shouldLog) {
    return
  }

  datadogRum.init({
    applicationId,
    clientToken,
    site,
    service,
    version,
    env,
    sessionSampleRate: sampleRate,
    sessionReplaySampleRate: sampleRate,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  })

  datadogLogs.init({
    clientToken,
    site,
    env,
    version,
    service,
    forwardConsoleLogs: ['warn', 'error']
  })

  datadogRum.startSessionReplayRecording()
}

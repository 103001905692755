/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CheckboxButton as CheckboxButtonProps } from 'interfaces/CheckboxButton'
import cx from 'classnames'
import classes from './CheckboxButton.module.css'

/* CheckboxButton is a simple component that mimics the visuals of an
Html <input type='checkbox'>. It does not actually behave like a checkbox
at all (e.g.: if used inside an Html <form>). Instead this is a controlled
component that expects the parent to handle the state of the "checked" prop
to toggle the checkmark, usually when the "onClick" event is invoked. */

/* KNOWN BUG: Since label is of type ReactNode, it can be an Html 
fragment. As such, it may contain <a> elements. When a link is clicked,
(i.e. when opening a EULA link in a new window) the checkbox will also
be toggled. */

const CheckboxButton = ({
  size = 'Small',
  label,
  labelClass,
  disabled,
  checked,
  onClick
}: CheckboxButtonProps) => {
  const handleClick = (
    e:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.KeyboardEvent<HTMLDivElement>
  ) => {
    // @ts-ignore
    if (!disabled && onClick) onClick(e)
  }

  return (
    <div
      role='button'
      data-cy='checkboxButton'
      tabIndex={0}
      onKeyDown={handleClick}
      onClick={handleClick}
      className={cx(
        classes.Checkbox,
        classes[size],
        disabled && classes.Disabled
      )}>
      <div>
        <i className='material-icons'>{checked ? 'check' : ''}</i>
      </div>
      {label && <div className={cx(classes.Label, labelClass)}>{label}</div>}
    </div>
  )
}

export default CheckboxButton

import { Dispatch, SetStateAction, useState } from 'react'
import Input from '../../../Shared/Input'
import Button from '../../../Shared/Button'
import BackTo from '../../../Shared/BackTo'
import classes from '../../../Shared/Container/Container.module.css'

interface Props {
  username: string
  buttonLabel: string
  returnUrl: string | null
  setUsername: Dispatch<SetStateAction<string>>
  setButtonLabel: Dispatch<SetStateAction<string>>
  sendEmail: () => void
}

function Body({
  username,
  setUsername,
  buttonLabel,
  setButtonLabel,
  returnUrl,
  sendEmail
}: Props) {
  const [preventOverclick, setPreventOverclick] = useState<boolean>(false)
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setUsername(value.trim())
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (preventOverclick) return

    setPreventOverclick(true)
    setButtonLabel('Sending Email...')
    sendEmail()
  }

  return (
    <div className={classes.FormContainer}>
      <form
        method='POST'
        className={classes.Form}
        data-form-primary
        onSubmit={handleSubmit}>
        <Input
          label='Username'
          onChange={onChange}
          value={username}
          autofocus={true}
          type='text'
          id='username'
        />
        <Button type='submit' label={buttonLabel} />
      </form>
      <BackTo returnUrl={returnUrl} />
    </div>
  )
}

export default Body

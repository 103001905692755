import { datadogLogs } from '@datadog/browser-logs'
import { ErrorParams } from '@interfaces'
import { Dispatch, SetStateAction, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Input from '../../../Shared/Input'
import * as tests from '../../../../util/PasswordValidation'
import PasswordCriteria from '../../../Shared/PasswordCriteria'
import Button from '../../../Shared/Button'
import { useAuth0 } from '@auth0/auth0-react'
import classes from '../../../Shared/Container/Container.module.css'

interface Props {
  setPasswordChanged: Dispatch<SetStateAction<boolean>>
}

function Body({ setPasswordChanged }: Props) {
  const authContext = useAuth0()
  const navigate = useNavigate()
  const [newPassword, setNewPassword] = useState<string>('')
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [eightCharLength, setEightCharLength] = useState<boolean>(false)
  const [containsLowerCase, setContainsLowerCase] = useState<boolean>(false)
  const [containsUpperCase, setContainsUpperCase] = useState<boolean>(false)
  const [containsIntegers, setContainsIntegers] = useState<boolean>(false)
  const [multiConditionalTest, setMultiConditionalTest] =
    useState<boolean>(false)
  const [
    confirmPasswordValidationMessage,
    setConfirmPasswordValidationMessage,
  ] = useState<string>('')
  const [showConfirmPasswordValidation, setShowConfirmPasswordValidation] =
    useState<boolean>(false)

  const {
    validateEightCharacters,
    validateInputContainsLowerCase,
    validateInputContainsUpperCase,
    validateInputContainsIntegers,
    validateMultiConditionalTest,
  } = tests

  const testResults = {
    eightCharLength,
    containsLowerCase,
    containsUpperCase,
    containsIntegers,
  }

  const runTests = (input: string) => {
    setEightCharLength(validateEightCharacters(input))
    setContainsLowerCase(validateInputContainsLowerCase(input))
    setContainsUpperCase(validateInputContainsUpperCase(input))
    setContainsIntegers(validateInputContainsIntegers(input))
    setMultiConditionalTest(validateMultiConditionalTest(input))
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const { value } = e.target
    switch (field) {
      case 'password':
        setNewPassword(value)
        runTests(value)
        break
      case 'confirm':
        setConfirmPassword(value)
        break
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (newPassword !== confirmPassword) {
      setShowConfirmPasswordValidation(true)
      setConfirmPasswordValidationMessage('Passwords must match')
      return
    }
    setNewPassword('')
    setConfirmPassword('')
    setSubmitting(true)

    const { getAccessTokenSilently } = authContext
    const token = await getAccessTokenSilently()
    const url = `https://${import.meta.env.VITE_APP_API}/auth0/updatePassword`
    const data = { newPassword, confirmPassword }
    try {
      const response = await fetch(url, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      })

      if (response.ok) {
        setPasswordChanged(true)
        setSubmitting(false)
        return
      }

      const json = await response.json()
      const { errors } = json
      const errorDetails = errors ? { errors } : undefined

      if (errors) {
        setShowConfirmPasswordValidation(true)
        setConfirmPasswordValidationMessage(json.title)
        datadogLogs.logger.error(json.title, errorDetails)
      }
    } catch (error: unknown) {
      const message: string = `Sorry, password change failed. Please try later.`
      datadogLogs.logger.error('Password change failed', { error })
      navigateToError(message)
    }
  }

  const navigateToError = (message: string) => {
    const title = 'Oops!'
    const queryParams: ErrorParams = {
      message,
      title,
    }
    const queryString = new URLSearchParams(
      Object.entries(queryParams)
    ).toString()

    navigate(`/error?${queryString}`)
  }

  return (
    <div className={classes.FormContainer}>
      <form
        method="POST"
        onSubmit={handleSubmit}
        className={classes.Form}
        data-form-primary
        autoComplete="new-password"
      >
        <Input
          label="New password"
          onChange={e => handleChange(e, 'password')}
          value={newPassword}
          autofocus
          id="password"
          type="password"
          readonly={isSubmitting}
        />
        <Input
          label="Re-enter new password"
          onChange={e => handleChange(e, 'confirm')}
          value={confirmPassword}
          id="confirm"
          type="password"
          readonly={isSubmitting}
        />
        {showConfirmPasswordValidation && (
          <span
            className={classes.InputErrorMessage}
            data-error-code="wrong-credentials"
          >
            <span
              className="material-symbols-outlined"
              role="img"
              aria-label="Error"
            >
              error
            </span>
            {confirmPasswordValidationMessage}
          </span>
        )}
        <PasswordCriteria {...testResults} />
        <Button
          type="submit"
          label="Change Password"
          disabled={!multiConditionalTest || isSubmitting}
        />
      </form>
    </div>
  )
}

export default Body

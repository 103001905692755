import { datadogLogs } from '@datadog/browser-logs'
import { Dispatch, SetStateAction } from 'react'

interface Props {
  username: string
  app: string | null
  returnUrl: string | null
  setEmailSent: Dispatch<SetStateAction<boolean>>
}

async function sendEmail({ username, app, returnUrl, setEmailSent }: Props) {
  const url = `https://${import.meta.env.VITE_APP_API}/auth0/sendForgotPasswordEmail`
  const data = { username, app, returnUrl }
  const body = JSON.stringify(data)

  try {
    await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body
    })
  } catch (error: unknown) {
    datadogLogs.logger.error('Forgot password form failed', { error })
  }

  setEmailSent(true)
}

export default sendEmail

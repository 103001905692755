import { datadogLogs } from '@datadog/browser-logs'
import { useLocation } from 'react-router-dom'
import Header from '../../Shared/Header'

function NotFound() {
  const location = useLocation()
  datadogLogs.logger.error('Page not found', { location })

  const description = (
    <>
      The page you are looking for might have been removed, had it&apos;s name
      changed, or is temporarily unavailable.
    </>
  )

  return (
    <>
      <Header title={'Page Not Found'} description={description} />
    </>
  )
}

export default NotFound

import { RegisteredUser, ErrorParams } from '@interfaces'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ErrorCode } from '../../../../util/ErrorCodeEnum'
import Input from '../../../Shared/Input'
import Button from '../../../Shared/Button'
import classes from '../../../Shared/Container/Container.module.css'
import linkClasses from '../../../Shared/BackTo/BackTo.module.css'

interface Props {
  user: RegisteredUser
  returnUrl: string
  isMobile?: boolean
}

function VerificationCode({ user, returnUrl, isMobile }: Props) {
  const navigate = useNavigate()
  const [verifiedUser, setUser] = useState<RegisteredUser>(user)
  const [resendLabel, setResendLabel] = useState<string>('Send New Code')
  const [resendHint, setResendHint] = useState<string>('')
  const [showError, setShowError] = useState<boolean>(false)
  const [errorLabel, setErrorLabel] = useState<string>('')
  const [showButton, setShowButton] = useState<boolean>(true)
  const [preventOverclickSubmit, setPreventOverclickSubmit] =
    useState<boolean>(false)
  const [preventOverclickResend, setPreventOverclickResend] =
    useState<boolean>(false)
  const submitError = `The verification code is incorrect or has expired. Re-enter the code, 
    or click "Send New Code" to receive a new code.`
  const resendError = 'There was a problem re-sending the verification code.'
  const unhandledError =
    'An error occurred creating the account. Please try again later.'

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setUser(prevState => ({ ...prevState, verificationCode: value }))
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (preventOverclickSubmit) return
    setPreventOverclickSubmit(true)

    try {
      // eslint-disable-next-line prettier/prettier
      const url = `https://${import.meta.env.VITE_APP_API}/register/withVerificationCode`
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(verifiedUser)
      })

      if (response.ok) {
        if (isMobile) {
          window.location.href = returnUrl
        } else {
          // TODO: https://auth0.com/docs/authenticate/login/auth0-universal-login/configure-default-login-routes
          window.location.href = `${
            import.meta.env.VITE_APP_CURBVIEW_AUTHORIZE_URL
          }?redirect_uri=${returnUrl}`
        }
      }

      if (!response.ok && response.status) {
        const data = await response.json()
        switch (data.errorCode) {
          case ErrorCode.InvalidVerificationCode:
            setPreventOverclickSubmit(false)
            setErrorLabel(submitError)
            setShowError(true)
            break
          case ErrorCode.UnknownException:
            console.error(data.title, data.errors)
            navigateToError(unhandledError)
            break
          default:
            setErrorLabel(data.title)
            setShowError(true)
            break
        }
      }
    } catch (error) {
      console.error(error)
      navigateToError(unhandledError)
    } finally {
      setUser(prevState => ({ ...prevState, verificationCode: '' }))
    }
  }

  const handleResend = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault()
    if (preventOverclickResend) return

    setUser(prevState => ({ ...prevState, verificationCode: '' }))
    setPreventOverclickResend(true)
    setShowError(false)
    setResendLabel('Sending...')
    setPreventOverclickSubmit(false)

    try {
      // eslint-disable-next-line prettier/prettier
      const url = `https://${import.meta.env.VITE_APP_API}/register/resendVerificationCode`
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(verifiedUser.email)
      })

      if (response.ok) {
        setShowButton(true)
      } else {
        const data = await response.json()
        console.error(data.title)
        resendErrors()
      }
    } catch (error) {
      console.error(error)
      resendErrors()
    }

    setResendLabel('Send New Code')
    setResendHint('Please make sure to use the most recent code.')
    setTimeout(() => {
      setPreventOverclickResend(false)
    }, 1000)
  }

  const resendErrors = () => {
    setShowError(true)
    setErrorLabel(resendError)
    setShowButton(false)
  }

  const navigateToError = (message: string) => {
    const params: ErrorParams = {
      message,
      returnUrl: `/create-user?r=${returnUrl}`,
      buttonLabel: 'Return to Create New Account'
    }
    const queryString = new URLSearchParams(Object.entries(params)).toString()
    navigate(
      `/error?${queryString}&a=${import.meta.env.VITE_APP_CURBVIEW_CLIENT_ID}`
    )
  }

  return (
    <div className={classes.FormContainer}>
      <form
        method='POST'
        onSubmit={handleSubmit}
        className={classes.Form}
        data-form-primary>
        <Input
          label='Verification Code'
          onChange={e => handleChange(e)}
          value={verifiedUser.verificationCode}
          autofocus
          id='verificationCode'
          type='text'
        />
        {showError && (
          <span className={classes.InputErrorMessage}>{errorLabel}</span>
        )}
        {showButton && (
          <Button
            type='submit'
            label='Continue'
            disabled={preventOverclickSubmit}
          />
        )}
      </form>
      <div className={linkClasses.Container}>
        <a className={linkClasses.Link} onClick={handleResend}>
          {resendLabel}
        </a>
        <br />
        <span style={{ fontSize: '13px' }}>{resendHint}</span>
      </div>
    </div>
  )
}

export default VerificationCode

const {
  VITE_APP_ATLAS_CLIENT_ID,
  VITE_APP_CURBVIEW_CLIENT_ID,
  VITE_APP_CURBVIEW_MOBILE_CLIENT_ID,
  VITE_APP_FLEXMLS_CLIENT_ID,
  VITE_APP_MONSOON_CLIENT_ID,
  VITE_APP_RAPIDSTATS_CLIENT_ID,
} = import.meta.env

const PRODUCTS = [
  {
    id: VITE_APP_ATLAS_CLIENT_ID || '',
    name: 'Atlas',
    logo: 'armls_logo.svg',
  },
  {
    id: VITE_APP_CURBVIEW_CLIENT_ID || '',
    name: 'CurbView',
    logo: 'curbview_logo.svg',
  },
  {
    id: VITE_APP_CURBVIEW_MOBILE_CLIENT_ID || '',
    name: 'CurbView',
    logo: 'curbview_logo.svg',
  },
  {
    id: VITE_APP_FLEXMLS_CLIENT_ID || '',
    name: 'FlexMLS',
    logo: 'flexmls_logo.svg',
  },
  {
    id: VITE_APP_MONSOON_CLIENT_ID || '',
    name: 'Monsoon',
    logo: 'monsoon_logo.svg',
  },
  {
    id: VITE_APP_RAPIDSTATS_CLIENT_ID || '',
    name: 'RapidStats',
    logo: 'rapidStats_logo.svg',
  },
]

export { PRODUCTS }

/* eslint-disable prettier/prettier */
import { ReactNode, useState } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import Header from '../../Shared/Header'
import Body from './Body'

function CreateUser() {
  const [description, setDescription] = useState<ReactNode>('')
  const [title, setTitle] = useState<string>('Create New Account')

  return (
    <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_APP_RECAPTCHA_KEY}>
      <Header title={title} description={description} condensed />
      <Body setTitle={setTitle} setDescription={setDescription} />
    </GoogleReCaptchaProvider>
  )
}

export default CreateUser

import cx from 'classnames'
import classes from './Title.module.css'

interface Props {
  text?: string
  condensed?: boolean
}

function Title({ text, condensed }: Props) {
  if (text) {
    return (
      <h1 className={cx(classes.Title, condensed && classes.Condensed)}>
        {text}
      </h1>
    )
  }

  return <></>
}

export default Title

import { Context } from '../Container'
import { useContext } from 'react'
import { Product, ContextType } from '@interfaces'
import classes from './Logo.module.css'

function Logo() {
  const context = useContext(Context) as ContextType
  const { product = {} as Product } = context
  const { logo = 'armls_logo.svg', name } = product
  const backgroundImage = `url(/images/${logo})`

  return (
    <div
      title={name || 'ARMLS'}
      className={classes.ProductImage}
      style={{ backgroundImage }}
    />
  )
}

export default Logo

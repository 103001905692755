import { ReactNode } from 'react'
import { Auth0Provider } from '@auth0/auth0-react'

interface Props {
  redirectRoute: string | null
  children: ReactNode
}

function Auth0Wrapper({ children, redirectRoute }: Props) {
  const domain = import.meta.env.VITE_APP_AUTH0_DOMAIN
  const clientId = import.meta.env.VITE_APP_AUTH0_CLIENT_ID
  const redirectUri = `${window.location.origin}/${redirectRoute}`
  const audience = import.meta.env.VITE_APP_AUTH0_AUDIENCE

  const onRedirectCallback = () => {}

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience,
        scope: 'openid profile email',
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0Wrapper

import { Context } from '../Container'
import { useContext } from 'react'
import classes from './SupportLink.module.css'

function SupportLink() {
  const context = useContext(Context)
  const { product = {} } = context
  const { name } = product

  if (name == 'CurbView') return <></>

  return (
    <div className={classes.SupportLink}>
      Having trouble?
      <br />
      Contact support at <a href="tel:480-921-7777">480-921-7777</a>
    </div>
  )
}

export default SupportLink

import { useState, forwardRef } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import classes from './Input.module.css'
import PasswordVisibilityToggle from './PasswordVisibilityToggle'
import { InputProps } from '../../../interfaces/InputType'

// eslint-disable-next-line react/display-name
const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      onChange,
      onBlur,
      onKeyUp,
      onKeyDown,
      value = '',
      autofocus,
      id = '',
      type = 'text',
      inputMode = 'text',
      readonly = false,
      required = true
    },
    ref
  ) => {
    const [mode, setMode] = useState<string>(type)

    const toggleMode = () => {
      if (mode === 'password') {
        setMode('text')
      } else if (mode === 'text') {
        setMode('password')
      }
    }

    return (
      <div className={classes.Wrapper}>
        <div
          className={`${classes.Container} ${
            value.length ? `${classes.Transform}` : ''
          }`}>
          <label className={`${classes.Label} ${classes.NoJS}`} htmlFor={id}>
            {label}
          </label>
          <input
            className={classes.Input}
            inputMode={inputMode}
            name={id}
            id={id}
            type={mode}
            value={value}
            required={required}
            autoComplete='new-password'
            autoCapitalize='none'
            autoFocus={autofocus}
            onChange={onChange}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            readOnly={readonly}
            ref={ref}
          />
          <div className={classes.Label} aria-hidden='true'>
            {label}
          </div>
          {type === 'password' && (
            <PasswordVisibilityToggle mode={mode} toggleMode={toggleMode} />
          )}
          <ReactTooltip id='show_password_tooltip' />
        </div>
      </div>
    )
  }
)

export default Input

import { datadogLogs } from '@datadog/browser-logs'
import { useLocation } from 'react-router-dom'
import Header from '../../Shared/Header'
import Body from './Body'

export default function AccessDenied() {
  const parameters = new URLSearchParams(useLocation().search)

  const decodeOrGetNull = (param: string | null) =>
    param ? decodeURIComponent(param) : null

  const buttonLabel: string | null = parameters.get('buttonLabel')
  const buttonUrl: string | null = decodeOrGetNull(parameters.get('buttonUrl'))
  const description: string | null = decodeOrGetNull(parameters.get('message'))
  const title = 'Access Denied'
  datadogLogs.logger.error(title, { parameters })

  return (
    <>
      <Header title={title} description={description} />
      <Body buttonLabel={buttonLabel} buttonUrl={buttonUrl} />
    </>
  )
}

import { PasswordCriteriaProps } from 'interfaces/PasswordCriteriaProps'
import classes from './PasswordCriteria.module.css'
import cx from 'classnames'

function PasswordCriteria({
  containsIntegers = false,
  containsLowerCase = false,
  containsUpperCase = false,
  eightCharLength = false,
  condensed = false
}: PasswordCriteriaProps) {
  return (
    <div className={cx(classes.Container, condensed && classes.Condensed)}>
      <div>Your password must contain:</div>
      <ul className={classes.List}>
        <li className={`${eightCharLength ? classes.Validated : ''}`}>
          At least 8 characters
        </li>
        <li className={`${containsLowerCase ? classes.Validated : ''}`}>
          At least 1 lower case letter (a-z)
        </li>
        <li className={`${containsUpperCase ? classes.Validated : ''}`}>
          At least 1 upper case letter (A-Z)
        </li>
        <li className={`${containsIntegers ? classes.Validated : ''}`}>
          At least 1 number (0-9)
        </li>
      </ul>
    </div>
  )
}

export default PasswordCriteria

import classes from './BackTo.module.css'

interface Props {
  returnUrl: string | null
  label?: string | null
}

function BackTo({ returnUrl, label = 'Back to Login' }: Props) {
  if (!returnUrl) {
    return <></>
  }

  return (
    <div className={classes.Container}>
      <a className={classes.Link} href={returnUrl}>
        {label}
      </a>
    </div>
  )
}

export default BackTo

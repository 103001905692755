import { ReactNode } from 'react'
import Logo from '../Logo'
import Title from '../Title'
import cx from 'classnames'
import classes from './Header.module.css'

interface Props {
  title?: string
  description?: ReactNode
  condensed?: boolean
}

function Header({ title, description = '', condensed }: Props) {
  return (
    <header className={cx(classes.Header, condensed && classes.Condensed)}>
      <Logo />
      <Title text={title} condensed />
      <p>{description}</p>
    </header>
  )
}

export default Header

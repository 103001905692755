import { Outlet } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import Container from './components/Shared/Container'
import Fallback from './components/Shared/Fallback'
import ChangePassword from './components/Routes/ChangePassword'
import ChangePasswordSSO from './components/Routes/ChangePasswordSSO'
import ForgotPassword from './components/Routes/ForgotPassword'
import AccessDenied from './components/Routes/AccessDenied'
import NotFound from './components/Routes/NotFound'
import Error from './components/Routes/Error'
import CreateUser from './components/Routes/CreateUser'

const ErrorBoundaryLayout = () => (
  <ErrorBoundary fallbackRender={Fallback}>
    <Container>
      <Outlet />
    </Container>
  </ErrorBoundary>
)

export default [
  {
    element: <ErrorBoundaryLayout />,
    children: [
      {
        path: '/change-password-authenticated',
        element: <ChangePasswordSSO />
      },
      { path: '/change-password', element: <ChangePassword /> },
      { path: '/forgot-password', element: <ForgotPassword /> },
      { path: '/access-denied', element: <AccessDenied /> },
      { path: '/create-user', element: <CreateUser /> },
      { path: '/error', element: <Error /> },
      { path: '*', element: <NotFound /> }
    ]
  }
]

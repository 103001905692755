import Header from '../Header'

interface Props {
  error: {
    message: string
  }
}

function Fallback({ error }: Props) {
  return (
    <>
      <Header title="Oops!" description={error.message} />
    </>
  )
}

export default Fallback

import Button from '../../Shared/Button'
import classes from '../../Shared/Container/Container.module.css'

interface Props {
  buttonLabel: string | null
  buttonUrl: string | null
}

function Body({ buttonLabel, buttonUrl }: Props) {
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    window.location.href = buttonUrl || 'https://armls.com'
  }

  return (
    <div className={classes.FormContainer}>
      <form
        method='GET'
        className={classes.Form}
        data-form-primary
        onSubmit={onSubmit}>
        <Button type='submit' label={buttonLabel || 'OK'} />
      </form>
    </div>
  )
}

export default Body

import { useLocation } from 'react-router-dom'
import Header from '../../Shared/Header'
import Body from './Body'

function Error() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const message = queryParams.get('message')
  const title = queryParams.get('title') || 'Oops!'
  const returnUrl = queryParams.get('returnUrl') || ''
  const buttonLabel = queryParams.get('buttonLabel') || ''

  return (
    <>
      <Header title={title} description={message} />
      {returnUrl && <Body buttonLabel={buttonLabel} buttonUrl={returnUrl} />}
    </>
  )
}

export default Error

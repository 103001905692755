import { useCallback, useEffect, useState, useRef, ReactNode } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { RegisteredUser, PasswordCriteriaProps, ErrorParams } from '@interfaces'
import { ErrorCode } from '../../../../util/ErrorCodeEnum'
import Input from '../../../Shared/Input'
import InputWithPasswordCriteria from '../../../Shared/Input/InputWithPasswordCriteria'
import InputError from '../../../Shared/InputError'
import BackTo from '../../../Shared/BackTo'
import CheckboxButton from '../../../Shared/CheckboxButton'
import Button from '../../../Shared/Button'
import * as passwordTests from '../../../../util/PasswordValidation'
import VerificationCode from '../VerificationCode'
import classes from '../../../Shared/Container/Container.module.css'

interface Props {
  setTitle: React.Dispatch<React.SetStateAction<string>>
  setDescription: React.Dispatch<React.SetStateAction<ReactNode>>
}

function Body({ setTitle, setDescription }: Props) {
  const navigate = useNavigate()
  const parameters = new URLSearchParams(useLocation().search)
  const returnUrl: string = parameters.get('r') as string
  const isMobile = returnUrl?.includes('://registered')
  const [showBackTo, setShowBackTo] = useState<boolean>(isMobile ? false : true)
  const [submitLabel, setSubmitLabel] = useState<string>('Continue')
  const [preventOverclickSubmit, setPreventOverclickSubmit] =
    useState<boolean>(false)
  const [showVerificationCode, setShowVerificationCode] =
    useState<boolean>(false)
  const unhandledError = 'An error occurred creating the account. Please try again later.'
  const ackknowledgeLabel = (
    <>
      I have read and agree to the CurbView{' '}
      <a href='https://curbview.com/terms' target='_blank' rel='noreferrer' tabIndex={-1}>
        Terms of Service
      </a>
      ,{' '}
      <a href='https://curbview.com/eula' target='_blank' rel='noreferrer' tabIndex={-1}>
        EULA
      </a>
      , and{' '}
      <a href='https://curbview.com/privacy' target='_blank' rel='noreferrer' tabIndex={-1}>
        Privacy Policy
      </a>
      .
    </>
  )
  // HACK: This isn't ideal for testing, but setting up env variables for one link is silly.
  // The "returnUrl" (above) is the important part:
  const backToUrl = 'https://curbview.com'

  // State management for forms fields:
  const [user, setUser] = useState<RegisteredUser>({
    email: '',
    firstName: '',
    lastName: '',
    username: '',
    password: '',
    acknowledgement: false
  })

  // Form validation messages:
  const [emailValidation, setEmailValidation] = useState<string>('')
  const [firstNameValidation, setFirstNameValidation] = useState<string>('')
  const [usernameValidation, setUsernameValidation] = useState<string>('')
  const [passwordValidation, setPasswordValidation] = useState<string>('')

  // Form validation visibility:
  const [showEmailValidation, setShowEmailValidation] = useState<boolean>(false)
  const [showFirstNameValidation, setShowFirstNameValidation] =
    useState<boolean>(false)
  const [showUsernameValidation, setShowUsernameValidation] =
    useState<boolean>(false)
  const [showPasswordValidation, setShowPasswordValidation] =
    useState<boolean>(false)

  // Form validation flags:
  const [isSubmitReady, setIsSubmitReady] = useState<boolean>(false)
  const [emailValidated, setEmailValidated] = useState<boolean>(false)
  const [usernameValidated, setUsernameValidated] = useState<boolean>(false)
  const [passwordValidated, setPasswordValidated] = useState<boolean>(false)
  const [firstnameValidated, setFirstnameValidated] = useState<boolean>(false)
  const [acknowledged, setAcknowledged] = useState<boolean>(false)

  // Password validation flags:
  const [eightCharLength, setEightCharLength] = useState<boolean>(false)
  const [containsLowerCase, setContainsLowerCase] = useState<boolean>(false)
  const [containsUpperCase, setContainsUpperCase] = useState<boolean>(false)
  const [containsIntegers, setContainsIntegers] = useState<boolean>(false)

  const passwordCriteriaProps: PasswordCriteriaProps = {
    eightCharLength,
    containsLowerCase,
    containsUpperCase,
    containsIntegers
  }

  const {
    validateEightCharacters,
    validateInputContainsLowerCase,
    validateInputContainsUpperCase,
    validateInputContainsIntegers,
    validateMultiConditionalTest
  } = passwordTests

  /* 
  The 'autofocus' attribute on the Email field caused the blur event to trigger on page load, displaying
  an error message to the user before they have attempted to enter information (bad UX). 
  Github Copilot: To prevent this, you could manage the focus state manually. Instead of using the autofocus
  attribute, you can use a ref in your React component to set the focus when the component mounts. 
  */
  const emailInputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus()
    }
  }, [])

  const { executeRecaptcha } = useGoogleReCaptcha()
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha('createuser')
    return token
  }, [executeRecaptcha])
  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  useEffect(() => {
    const isReady =
      emailValidated &&
      passwordValidated &&
      firstnameValidated &&
      usernameValidated &&
      acknowledged
    setIsSubmitReady(isReady)
    setPreventOverclickSubmit(false)
  }, [
    emailValidated,
    passwordValidated,
    usernameValidated,
    firstnameValidated,
    acknowledged
  ])

  const runPasswordTests = (input: string): boolean => {
    setEightCharLength(validateEightCharacters(input))
    setContainsLowerCase(validateInputContainsLowerCase(input))
    setContainsUpperCase(validateInputContainsUpperCase(input))
    setContainsIntegers(validateInputContainsIntegers(input))

    return validateMultiConditionalTest(input)
  }

  const handlers = {
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        const form = e.currentTarget.form
        if (form) {
          const formEvent = e as unknown as React.FormEvent<HTMLFormElement>
          handleSubmit(formEvent)
        }
      }
    },
    onKeyUp: async (e: React.KeyboardEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement
      const { id: element, value } = target
      switch (element) {
        case 'email':
          // eslint-disable-next-line prettier/prettier
          const emailOkay = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value.trim())
          setShowEmailValidation(false)
          setEmailValidated(emailOkay)
          break
        case 'firstName':
          const firstnameOkay = value.length > 0
          setShowFirstNameValidation(false)
          setFirstnameValidated(firstnameOkay)
          break
        case 'username':
          const usernameOkay = /^[A-Za-z0-9][A-Za-z0-9+@\-_]{5,49}$/.test(value.trim())
          setShowUsernameValidation(false)
          setUsernameValidated(usernameOkay)
          break
        case 'password':
          setShowPasswordValidation(false)
          const passwordOkay = runPasswordTests(value)
          setPasswordValidated(passwordOkay)
          break
      }
    },
    onBlur: ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const { id: element, value } = target
      switch (element) {
        case 'email':
          // eslint-disable-next-line prettier/prettier
          const emailOkay = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value.trim())
          setEmailValidated(emailOkay)

          if (!emailOkay || !value.length) {
            const validationError = value.length
              ? 'Email address does not appear to be valid.'
              : 'Email is required.'
            setIsSubmitReady(false)
            setEmailValidation(validationError)
          }

          setShowEmailValidation(!emailOkay)
          break
        case 'firstName':
          const firstnameOkay = value.length > 0
          setFirstnameValidated(firstnameOkay)

          if (!firstnameOkay) {
            setFirstNameValidation('First name is required.')
            setShowFirstNameValidation(true)
          }

          setShowFirstNameValidation(!firstnameOkay)
          break
        case 'username':
          const usernameOkay = /^[A-Za-z0-9][A-Za-z0-9+@\-_]{5,49}$/.test(value.trim())
          setUsernameValidated(usernameOkay)

          if (!value.length) {
            setUsernameValidation('Username is required.')
          } else if (!usernameOkay) {
            const validationError =
              value.length >= 6 && value.length <= 50
                ? 'Username must start with a letter or number with no spaces and limited special characters.'
                : 'Username must be between 6 and 50 characters.'
            setUsernameValidation(validationError)
          }

          setShowUsernameValidation(!usernameOkay)
          break
        case 'password':
          const passwordOkay = runPasswordTests(value)
          setPasswordValidated(passwordOkay)

          if (!passwordOkay) {
            const validationError = value.length
              ? 'Password does not meet complexity requirements.'
              : 'Password is required.'
            setPasswordValidation(validationError)
          }

          setShowPasswordValidation(!passwordOkay)
          break
      }
    },
    // NOTE: onChange is the only event that React will accept for managing controlled elements.
    // onKeyUp and onBlur are my preferred event handlers for form validation (jds):
    onChange: ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const { id: element, value } = target
      setUser(prevState => ({ ...prevState, [element]: value.trim() }))
    },
    onAcknowledgeClick: () => {
      setAcknowledged(prevState => !prevState)
      setUser(prevState => ({ ...prevState, acknowledgement: !acknowledged }))
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!isSubmitReady) return
    if (preventOverclickSubmit) return

    setSubmitLabel('Creating Account...')
    setIsSubmitReady(false)
    setPreventOverclickSubmit(true)

    const googleCaptchaToken = await handleReCaptchaVerify()
    const captchaUser = { ...user, googleCaptchaToken: googleCaptchaToken }

    try {
      const url = `https://${import.meta.env.VITE_APP_API}/register/withCaptcha`
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(captchaUser)
      })

      if (response.ok) {
        window.location.href = returnUrl
      }

      if (!response.ok && response.status) {
        setSubmitLabel('Continue')
        const data = await response.json()
        switch (data.errorCode) {
          case ErrorCode.UserAlreadyExists:
            setUsernameValidated(false)
            setUsernameValidation('Username is already taken.')
            setShowUsernameValidation(true)
            break
          case ErrorCode.EmailAlreadyExists:
            setEmailValidated(false)
            setEmailValidation('Email is already taken.')
            setShowEmailValidation(true)
            break
          case ErrorCode.CaptchaScoreFailed:
            setShowVerificationCode(true)
            setTitle('Verification Code Sent')
            setShowBackTo(false)
            setDescription(
              <>
                Please check your email for the verification code.
                <br />
                Enter the code below to complete your registration.
              </>
            )
            break
          case ErrorCode.SendVerificationCodeFailure:
            setShowVerificationCode(true)
            setTitle('Error Sending Verification Code')
            setShowBackTo(false)
            setDescription(
              <>
                There was a problem sending the Verification Code.
                <br />
                Click on Send New Code below to try again.
              </>
            )
            break
          case ErrorCode.CaptchaException:
          case ErrorCode.UnknownException:
          default:
            console.error(data.title, data.errorCode, data.message)
            navigateToError(unhandledError)
            break
        }
      }
    } catch (error) {
      console.error(error)
      navigateToError(unhandledError)
    }
  }

  const navigateToError = (message: string) => {
    const params: ErrorParams = {
      message,
      returnUrl: `/create-user?r=${returnUrl}`,
      buttonLabel: 'Return to Create New Account'
    }
    const queryString = new URLSearchParams(Object.entries(params)).toString()
    navigate(
      `/error?${queryString}&a=${import.meta.env.VITE_APP_CURBVIEW_CLIENT_ID}`
    )
  }

  const commonHandlers = {
    onBlur: handlers.onBlur,
    onChange: handlers.onChange,
    onKeyUp: handlers.onKeyUp,
    onKeyDown: handlers.onKeyDown
  }

  return (
    <>
      {showVerificationCode ? (
        <VerificationCode user={user} returnUrl={returnUrl} isMobile={isMobile} />
      ) : (
        <div className={classes.FormContainer}>
          <form method='POST' onSubmit={handleSubmit} className={classes.Form}>
            <Input
              label='Email'
              value={user.email}
              id='email'
              type='email'
              ref={emailInputRef}
              {...commonHandlers}
            />
            {showEmailValidation && <InputError label={emailValidation} />}
            <Input
              label='First Name'
              value={user.firstName}
              id='firstName'
              type='text'
              {...commonHandlers}
            />
            {showFirstNameValidation && (
              <InputError label={firstNameValidation} />
            )}
            <Input
              label='Last Name'
              value={user.lastName}
              id='lastName'
              required={false}
              type='text'
              {...commonHandlers}
            />
            <Input
              label='Username'
              value={user.username}
              id='username'
              type='text'
              {...commonHandlers}
            />
            {showUsernameValidation && (
              <InputError label={usernameValidation} />
            )}
            <InputWithPasswordCriteria
              {...commonHandlers}
              label='Password'
              id='password'
              type='password'
              value={user.password}
              passwordCriteriaProps={passwordCriteriaProps}
            />
            {showPasswordValidation && (
              <InputError label={passwordValidation} />
            )}
            <CheckboxButton
              label={ackknowledgeLabel}
              checked={acknowledged}
              onClick={handlers.onAcknowledgeClick}
            />
            <Button
              type='submit'
              label={submitLabel}
              disabled={!isSubmitReady}
            />
          </form>
          {showBackTo && (
            <BackTo returnUrl={backToUrl} label='Back to CurbView' />
          )}
        </div>
      )}
    </>
  )
}

export default Body

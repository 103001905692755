import { useEffect, useState, useContext, useRef } from 'react'
import { Context } from '../Container'
import classes from './Snackbar.module.css'

const autoHideDuration = 3000

export function Snackbar() {
  const timeoutRef = useRef(null)
  const cancelTimeoutRef = useRef(false)
  const [isVisible, setIsVisible] = useState(false)
  const context = useContext(Context)

  if (!context) {
    throw new Error('Component must be wrapped in a Context.Provider')
  }

  const { snackbar: message, setSnackbar } = context

  useEffect(() => {
    if (message) {
      setIsVisible(true)
      cancelTimeoutRef.current = false
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = setTimeout(() => {
        if (!cancelTimeoutRef.current) {
          setIsVisible(false)
          setSnackbar('')
        }
      }, autoHideDuration)
    }
    return () => {
      if (message && timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [message])

  const handleClickClose = () => {
    setIsVisible(false)
    setSnackbar('')
  }

  if (!message || !isVisible) {
    return <div />
  }

  return (
    <div className={classes.Root}>
      <div className={classes.Snackbar}>
        <div className={classes.Header}>
          <div id='snackbarTitle' className={classes.Title}>
            {message}
          </div>
          <button
            id='snackbarCloseButton'
            type='button'
            onClick={handleClickClose}
            className={classes.Close}
          >
            <i className='material-icons'>close</i>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Snackbar

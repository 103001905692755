import { useState, createContext } from 'react'
import { useLocation, useMatch } from 'react-router-dom'
import { PRODUCTS } from '../../../util/constants'
import cx from 'classnames'
import SupportLink from '../SupportLink'
import Snackbar from '../Snackbar'
import { Product, ContextType } from '@interfaces'
import classes from './Container.module.css'
const { VITE_APP_CURBVIEW_CLIENT_ID } = import.meta.env

interface Props {
  children: React.ReactNode
}

export const Context = createContext<ContextType | undefined>(undefined)

function Container({ children }: Props) {
  const [snackbar, setSnackbar] = useState('')
  const parameters = new URLSearchParams(useLocation().search)
  const createUserPath = useMatch('/create-user')
  // NOTE: Creating new users is always a CurbView feature regardless of the app id:
  const app: string | null = createUserPath
    ? VITE_APP_CURBVIEW_CLIENT_ID || ''
    : parameters.get('a') || parameters.get('app')
  const product: Product | undefined = PRODUCTS.find(p => p.id === app)
  const cssCascade = product?.name || ''

  return (
    <Context.Provider value={{ snackbar, setSnackbar, product }}>
      <div className={classes.PromptWrapper}>
        <main className={cx(classes.Main, classes[cssCascade])}>
          <section className={classes.Section}>
            <div className={cx(classes.Gradient)}></div>
            <div className={classes.Wrapper}>
              <div className={classes.CenterChildren}>
                {children}
                <SupportLink />
              </div>
            </div>
          </section>
        </main>
        <div className={classes.Copyright}>
          &copy;&nbsp;{new Date().getFullYear()}&nbsp;Provided by ARMLS
        </div>
      </div>
      <div className={cx(classes.HeroImage, classes[cssCascade])}></div>
      <Snackbar />
    </Context.Provider>
  )
}

export default Container

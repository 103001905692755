import classes from '../Container/Container.module.css'

interface Props {
  label: string
  showIcon?: boolean
}

function InputError({ label, showIcon }: Props) {
  return (
    <span className={classes.InputErrorMessage}>
      {showIcon && (
        <span
          className='material-symbols-outlined'
          role='img'
          aria-label='Error'>
          error
        </span>
      )}
      {label}
    </span>
  )
}

export default InputError

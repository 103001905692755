import { datadogLogs } from '@datadog/browser-logs'
import { ErrorParams, PasswordCriteriaProps } from '@interfaces'
import { Dispatch, SetStateAction, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Input from '../../../Shared/Input'
import * as tests from '../../../../util/PasswordValidation'
import PasswordCriteria from '../../../Shared/PasswordCriteria'
import Button from '../../../Shared/Button'
import BackTo from '../../../Shared/BackTo'
import classes from '../../../Shared/Container/Container.module.css'

interface Props {
  showBackTo: boolean
  setPasswordChanged: Dispatch<SetStateAction<boolean>>
}

function Body({ setPasswordChanged, showBackTo }: Props) {
  const parameters = new URLSearchParams(useLocation().search)
  const returnUrl: string = parameters.get('r') as string
  const username: string = parameters.get('u') as string
  const passwordResetToken: string = parameters.get('t') as string
  const navigate = useNavigate()
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [eightCharLength, setEightCharLength] = useState<boolean>(false)
  const [containsLowerCase, setContainsLowerCase] = useState<boolean>(false)
  const [containsUpperCase, setContainsUpperCase] = useState<boolean>(false)
  const [containsIntegers, setContainsIntegers] = useState<boolean>(false)
  const [multiConditionalTest, setMultiConditionalTest] =
    useState<boolean>(false)
  const [
    confirmPasswordValidationMessage,
    setConfirmPasswordValidationMessage
  ] = useState<string>('')
  const [showConfirmPasswordValidation, setShowConfirmPasswordValidation] =
    useState<boolean>(false)

  const {
    validateEightCharacters,
    validateInputContainsLowerCase,
    validateInputContainsUpperCase,
    validateInputContainsIntegers,
    validateMultiConditionalTest
  } = tests

  const passwordCriteriaProps: PasswordCriteriaProps = {
    eightCharLength,
    containsLowerCase,
    containsUpperCase,
    containsIntegers
  }

  const runTests = (input: string) => {
    setEightCharLength(validateEightCharacters(input))
    setContainsLowerCase(validateInputContainsLowerCase(input))
    setContainsUpperCase(validateInputContainsUpperCase(input))
    setContainsIntegers(validateInputContainsIntegers(input))
    setMultiConditionalTest(validateMultiConditionalTest(input))
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const { value } = e.target
    switch (field) {
      case 'password':
        setShowConfirmPasswordValidation(false)
        setConfirmPasswordValidationMessage('')
        setNewPassword(value)
        runTests(value)
        break
      case 'confirm':
        setShowConfirmPasswordValidation(false)
        setConfirmPasswordValidationMessage('')
        setConfirmPassword(value)
        break
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (newPassword !== confirmPassword) {
      setShowConfirmPasswordValidation(true)
      setConfirmPasswordValidationMessage('Passwords must match')
      return
    }
    setNewPassword('')
    setConfirmPassword('')

    const url = `https://${import.meta.env.VITE_APP_API}/auth0/resetPassword`
    const data = { username, passwordResetToken, newPassword, confirmPassword }
    try {
      const response = await fetch(url, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })

      if (response.ok) {
        setPasswordChanged(true)
        return
      }

      const json = await response.json()
      const { errors } = json
      const errorDetails = errors ? { errors } : undefined

      if (errors?.[0]?.code === 'InvalidToken') {
        datadogLogs.logger.error(json.title, errorDetails)
        const message: string = `We're sorry, the change password link we emailed you is
no longer valid. Please click the button below to try again.`
        navigateToError(message)
      } else {
        setShowConfirmPasswordValidation(true)
        setConfirmPasswordValidationMessage(json.title)
        datadogLogs.logger.error(json.title, errorDetails)
      }
    } catch (error: unknown) {
      const message: string = `Sorry, password change failed. Please try later.`
      datadogLogs.logger.error('Password change failed', { error })
      navigateToError(message)
    }
  }

  const navigateToError = (message: string) => {
    const title = 'Oops!'
    const buttonLabel = 'Change Password'
    const queryParams: ErrorParams = {
      message,
      title,
      returnUrl,
      buttonLabel
    }
    const queryString = new URLSearchParams(
      Object.entries(queryParams)
    ).toString()

    navigate(`/error?${queryString}`)
  }

  return (
    <div className={classes.FormContainer}>
      <form
        method='POST'
        onSubmit={handleSubmit}
        className={classes.Form}
        data-form-primary
        autoComplete='new-password'>
        <Input
          label='New password'
          onChange={e => handleChange(e, 'password')}
          value={newPassword}
          autofocus
          id='password'
          type='password'
        />
        <Input
          label='Re-enter new password'
          onChange={e => handleChange(e, 'confirm')}
          value={confirmPassword}
          id='confirm'
          type='password'
        />
        {showConfirmPasswordValidation && (
          <span
            className={classes.InputErrorMessage}
            data-error-code='wrong-credentials'>
            <span
              className='material-symbols-outlined'
              role='img'
              aria-label='Error'>
              error
            </span>
            {confirmPasswordValidationMessage}
          </span>
        )}
        <PasswordCriteria {...passwordCriteriaProps} />
        <Button
          type='submit'
          label='Change Password'
          disabled={!multiConditionalTest}
        />
      </form>
      {showBackTo && <BackTo returnUrl={returnUrl} />}
    </div>
  )
}

export default Body

import { Context } from '../Container'
import { useContext } from 'react'
import cx from 'classnames'
import classes from './Button.module.css'

interface Props {
  label: string
  type: 'button' | 'submit' | 'reset'
  handleClick?: () => void
  disabled?: boolean
}

const Button = ({ label, type, handleClick, disabled }: Props) => {
  const context = useContext(Context)
  const { product = {} } = context

  return (
    <button
      disabled={disabled}
      type={type}
      tabIndex={0}
      className={cx(classes.Button, classes[product?.name])}
      onClick={handleClick}>
      {label}
    </button>
  )
}

export default Button

import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Header from '../../Shared/Header'
import Body from './Body'
import EmailSent from './EmailSent'
import sendEmail from './sendEmail'

export default function ForgotPassword() {
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [username, setUsername] = useState<string>('')
  const description: string =
    'Enter your username and we will send you instructions to reset your password.'
  const [buttonLabel, setButtonLabel] = useState<string>('Continue')
  const parameters = new URLSearchParams(useLocation().search)
  const app: string | null = parameters.get('app') || parameters.get('a')
  const returnUrl: string | null = parameters.get('returnUrl')
  const title = 'Forgot Your Password?'

  async function handleSendEmail() {
    await sendEmail({ username, app, returnUrl, setEmailSent })
  }

  if (emailSent) {
    return <EmailSent returnUrl={returnUrl} />
  }

  return (
    <>
      <Header title={title} description={description} />
      <Body
        username={username}
        setUsername={setUsername}
        buttonLabel={buttonLabel}
        setButtonLabel={setButtonLabel}
        returnUrl={returnUrl}
        sendEmail={handleSendEmail}
      />
    </>
  )
}

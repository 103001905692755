import Auth0Wrapper from '../../Shared/Auth0Wrapper'
import Authentication from './Authentication'

function ChangePassword() {
  return (
    <Auth0Wrapper redirectRoute={'change-password-authenticated'}>
      <Authentication />
    </Auth0Wrapper>
  )
}

export default ChangePassword

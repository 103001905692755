import { datadogLogs } from '@datadog/browser-logs'
import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from '../../../Shared/Loading'
import Body from '../Body'
import Header from '../../../Shared/Header'

function Authentication() {
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    loginWithRedirect,
  } = useAuth0()

  const [isWaitingOnToken, setWaitingOnToken] = useState<boolean>(false)
  const [passwordChanged, setPasswordChanged] = useState<boolean>(false)
  const [authenticationError, setAuthenticationError] = useState<string>()

  useEffect(() => {
    fetchToken()
  }, [])

  const fetchToken = async () => {
    try {
      setWaitingOnToken(true)
      await getAccessTokenSilently()
      setWaitingOnToken(false)
    } catch (error: unknown) {
      handleTokenError(error)
    }
  }

  const handleTokenError = async (error: unknown) => {
    if (error?.message === 'Consent required') {
      try {
        await getAccessTokenWithPopup()
        setWaitingOnToken(false)
      } catch {
        setAuthenticationError('Ensure popups are enabled for this site.')
      }
    } else if (error?.message === 'Login required') {
      // NOTE: The Auth0 wrapper passes along the clientId for 'ARMLS Auth0 Micro-Site' to
      // the login screen. Custom metadata on the Micro-Site application in the Auth0 tenant
      // brands the login as "Flex MLS" since that is the only application that currently
      // uses ChangePasswordSSO. The fragment below informs the change-password-authenticated
      // route to use the Flex MLS brand as well.
      loginWithRedirect({
        fragment: `&a=${import.meta.env.VITE_APP_FLEXMLS_CLIENT_ID}`,
      })
    } else {
      datadogLogs.logger.error('Authentication error..', error?.messsage)
      setAuthenticationError(error?.message)
      setWaitingOnToken(false)
    }
  }

  const renderLoading = () => (
    <>
      <Header title={'Verifying...'} />
      <Loading />
    </>
  )

  const renderPasswordChanged = () => (
    <>
      <Header title={'Your password has been successfully updated.'} />
    </>
  )

  const renderAuthenticatedBody = () => (
    <>
      <Header title={'Change Your Password'} />
      <Body setPasswordChanged={setPasswordChanged} />
    </>
  )

  const renderAuthenticationError = () => (
    <>
      <Header
        title={'Authentication Error'}
        description={authenticationError}
      />
    </>
  )

  if (isLoading || isWaitingOnToken) {
    return renderLoading()
  }

  if (passwordChanged) {
    return renderPasswordChanged()
  }

  if (isAuthenticated) {
    return renderAuthenticatedBody()
  }

  if (authenticationError) {
    return renderAuthenticationError()
  }

  // Default case when none of the conditions meet.
  return <></>
}

export default Authentication

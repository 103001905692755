import { Context } from '../../Container'
import { useContext } from 'react'
import { Product, ContextType } from '@interfaces'
import cx from 'classnames'
import classes from '../PasswordInput.module.css'

interface Props {
  mode: string
  toggleMode: () => void
}

function PasswordVisibilityToggle({ mode, toggleMode }: Props) {
  const context = useContext(Context) as ContextType
  const { product = {} as Product } = context

  return (
    <button
      className={classes.Button}
      onClick={toggleMode}
      type='button'
      data-action='toggle'
      tabIndex={-1}
      data-tooltip-id='show_password_tooltip'
      data-tooltip-content={mode === 'text' ? 'Hide password' : 'Show password'}
      data-tooltip-place='top'>
      <span className={cx('material-symbols-outlined', classes[product?.name || ''])}>
        {mode === 'text' ? 'visibility_off' : 'visibility'}
      </span>
    </button>
  )
}

export default PasswordVisibilityToggle

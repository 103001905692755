import { Context } from '../../Container'
import { useContext, useState } from 'react'
import { Product, PasswordCriteriaProps, ContextType } from '@interfaces'
import PasswordCriteria from '../../PasswordCriteria'
import cx from 'classnames'
import classes from '../PasswordInput.module.css'

interface Props {
  passwordCriteriaProps?: PasswordCriteriaProps
}

function PasswordCriteriaToggle({ passwordCriteriaProps }: Props) {
  const context = useContext(Context) as ContextType
  const { product = {} as Product } = context

  const criteriaCondensed = { ...passwordCriteriaProps, condensed: true }
  const [visible, setVisible] = useState(false)

  return (
    <button
      className={classes.Button}
      style={{ right: '31px' }}
      onClick={() => setVisible(!visible)}
      type='button'
      tabIndex={-1}
      data-action='toggle'
      data-tooltip-id='show_password_criteria_tooltip'
      data-tooltip-content='Password Criteria'
      data-tooltip-place='top'>
      <span className={cx('material-symbols-outlined', classes[product?.name || ''])}>
        info
      </span>
      <div
        id='ComplexityPopup'
        className={cx(classes.Popover, visible && classes.Visible)}>
        <PasswordCriteria {...criteriaCondensed} />
      </div>
    </button>
  )
}

export default PasswordCriteriaToggle

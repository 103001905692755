import { useLocation } from 'react-router-dom'
import classes from './PasswordChanged.module.css'
import Button from '../../../Shared/Button'
import jwt_decode from 'jwt-decode'
import { Auth0SessionToken } from '@interfaces'

function PasswordChanged() {
  const location = useLocation()
  const parameters = new URLSearchParams(location.search)
  const returnUrl: string | null = parameters.get('r')

  // EXPIRED PASSWORD DETECTED ON LOGIN:
  // A session token will exist when the users password is expired
  // and they are prompted by Auth0 to change it on login.
  // If a session token is present, decode it.
  const sessionToken: string | null = parameters.get('session_token')
  const session: Auth0SessionToken | null = sessionToken
    ? jwt_decode<Auth0SessionToken>(sessionToken)
    : null

  // EXPIRED PASSWORD DETECTED ON LOGIN:
  // In order for Auth0 to continue the authorization flow, the original state param
  // needs to included when the user is redirect to the continue_uri
  const state: string | null = parameters.get('state')

  // EXPIRED PASSWORD DETECTED ON LOGIN:
  // If we have a session, use it's continue_uri as a form action
  // instead of redirecting the user within the onSubmit function.
  const action = session?.continue_uri
    ? `${session.continue_uri}?state=${state}`
    : undefined

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (returnUrl) {
      window.location.href = returnUrl
    }
  }

  return (
    <section className={classes.Section}>
      <form
        method="POST"
        action={action}
        className={classes.Etc}
        onSubmit={!action ? handleSubmit : undefined}
      >
        <Button type="submit" label="Login" />
        {sessionToken && (
          <input type="hidden" name="session_token" value={sessionToken} />
        )}
        {session?.state && (
          <input type="hidden" name="state" value={session?.state} />
        )}
      </form>
    </section>
  )
}

export default PasswordChanged

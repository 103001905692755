export function validateEightCharacters(input: string) {
  const regex = /^.{8,}$/
  return regex.test(input)
}

export function validateNoRepeatingCharacterSequence(input: string) {
  const regex = /(.)\1{2,}/
  if (input.length) {
    return !regex.test(input)
  }
  return false
}

export function validateInputContainsLowerCase(input: string) {
  const regex = /[a-z]/
  return regex.test(input)
}

export function validateInputContainsUpperCase(input: string) {
  const regex = /[A-Z]/
  return regex.test(input)
}

export function validateInputContainsIntegers(input: string) {
  const regex = /[0-9]/
  return regex.test(input)
}

export function validateMultiConditionalTest(input: string) {
  const tests = [
    validateInputContainsIntegers,
    validateInputContainsLowerCase,
    validateInputContainsUpperCase,
    validateEightCharacters
  ]
  const passedTests = tests.filter(test => test(input))
  return passedTests.length == 4 ? true : false
}
